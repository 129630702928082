import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MobileMenu from './MobileMenu'

const Header = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (match && match.params.id) {
      window.scrollTo(0, 0)
      setMenuOpen(false)
    }
  }, [match])
  const [activeMenu, setActiveMenu] = useState(false)
  return (
    <div>
      {window.innerWidth > 991 ? (
        <header>
          <div className="header">
            <div className="container">
              <div className="header-flex">
                <div className="top-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/roadsidefamilyfoundation/"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/roadsidefamilyfoundation/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/roadside_family?lang=en"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCdIaK1zy27t6NQIi1w3EoOw"
                        target="_blank"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="top-number">
                  <div className="top-number-ul">
                    <ul>
                      <li>
                        <a href="tel:8657457237">
                          <i className="fa fa-phone" /> +91 8657457237
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@roadsidefamilyfoundation.org">
                          <i className="fa fa-envelope" />
                          info@roadsidefamilyfoundation.org
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end header */}
          <div className="container">
            <div className="after-header">
              <div className="navbar">
                <nav>
                  <div className="logo">
                    <Link to="/">
                      <img src="/images/logo.png" />
                    </Link>
                  </div>
                  <div
                    className="nav-links text-center"
                    style={{ padding: '0px 20px' }}
                  >
                    <i className="fa fa-close" onclick="hideMenu()" />
                    <ul>
                      <li>
                        <Link to="/" className="active">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/projects"> Projects</Link>
                      </li>
                      <li>
                        <Link to="/donate-scrap"> Donate Scrap</Link>
                      </li>
                      <li>
                        <Link to="/gallery"> Gallery</Link>
                      </li>

                      <li
                        onMouseEnter={() => setActiveMenu(true)}
                        onMouseLeave={() => setActiveMenu(false)}
                      >
                        <a href="#">How Can You help?</a>
                        <ul
                          className="dropdown-list"
                          style={{ display: activeMenu ? 'block' : 'none' }}
                        >
                          <li>
                            <Link to="/donate-now">Donation</Link>
                          </li>
                          <li>
                            <Link to="/become-a-volunteer">Volunteering</Link>
                          </li>
                          <li>
                            <Link to="/become-a-member">Become A member</Link>
                          </li>
                          <li>
                            <Link to="/internship">Internship</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">CONTACT US</Link>
                      </li>
                    </ul>
                  </div>
                  <i className="fa fa-bars" onclick="showMenu()" />
                </nav>
              </div>
              <div className="donate-now">
                <Link to="/donate-now" className="btn btn-donate">
                  DONATE NOW
                </Link>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <MobileMenu />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
