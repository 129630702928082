import {
  GET_TEAMS,
  TOTAL_TEAMS,
  ADD_TEAM,
  EDIT_TEAM,
  GET_TEAM,
  RESET_TEAM,
  TEAMS_ERROR,
} from "../actions/types";

const initialState = {
  teams: null,
  team: null,
  total_teams: 0,
  loading: true,
  error: {},
  team_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: payload,
        loading: false,
        error: {},
      };

    case TOTAL_TEAMS:
      return {
        ...state,
        total_teams: payload,
        loading: false,
        error: {},
      };
    case RESET_TEAM:
      return {
        ...state,
        teams: null,
        team: null,
        loading: true,
        error: {},
      };

    case ADD_TEAM:
      return {
        ...state,
        team_message: payload,
        loading: false,
        error: {},
      };
    case GET_TEAM:
      return {
        ...state,
        team: payload,
        loading: false,
        error: {},
      };
    case EDIT_TEAM:
      return {
        ...state,
        team_message: payload,
        loading: false,
        error: {},
      };

    case TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
