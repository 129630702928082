import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import gallery from "./gallery";
import testimonial from "./testimonial";
import blog from "./blog";
import cause from "./upcoming-project";
import project from "./project";
import team from "./team";
import volunteer from "./volunteer";
import contact from "./contact";
import faq from "./faq";
import donation from "./donation";
import page from "./page";
import event from "./event";
import scrap from "./scrap";
export default combineReducers({
  auth,
  alert,
  gallery,
  testimonial,
  blog,
  cause,
  project,
  team,
  volunteer,
  contact,
  faq,
  page,
  donation,
  event,
  scrap,
});
