import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { URI } from "../constants/constants";
import { addNewsletter } from "../actions/newsletters";
const Footer = ({ addNewsletter }) => {
  const [newsletter, setNewsletter] = useState("");
  const [newsletterError, setNewsletterError] = useState(false);
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);
  const [submittingNewsletter, setSubmittingNewsletter] = useState(false);
  const newsletterSubmit = async () => {
    setSubmittingNewsletter(true);
    setNewsletterError(false);
    if (newsletter === "") {
      setNewsletterError(true);
      setNewsletterSuccess(false);
      setSubmittingNewsletter(false);
    } else {
      await addNewsletter({ newsletter });
      setNewsletterSuccess(true);
      setNewsletterError(false);
      setSubmittingNewsletter(false);
    }
  };
  return (
    <footer>
      <section
        className="ptb-50 footer"
        style={{
          backgroundImage: `url(/images/footer-bg.png)`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  {" "}
                  <img src="/images/white-RFF-logo-300x243.png" />
                </Link>
                <p>
                  ROADSIDE FAMILY FOUNDATION is a section-8, Non-profit
                  organization founded in May 2020, registered with Ministry of
                  Corporate Affairs, Government of India
                </p>
              </div>
              <div className="social-med">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/roadsidefamilyfoundation/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/roadsidefamilyfoundation/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/roadside_family?lang=en"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCdIaK1zy27t6NQIi1w3EoOw"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-links">
                <h3>PAGES</h3>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/projects"> Projects</Link>
                  </li>

                  <li>
                    <Link to="/become-a-volunteer">Become A Volunteer</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <h3>QUICK LINK</h3>
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/donor-privacy-policy">Donor Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <h3>CONTACT US</h3>
                <p>
                  CEO 95, 122, UNIT NO 29, BOX A4, AAREY MILK COLONY, GOREGAON
                  EAST, MUMBAI 400065
                </p>
                <div className="number-flex">
                  <div className="num-text">
                    <h5><a href="tel:8657457237">(+91) 8657457237</a></h5>
                    <h4>info@roadsidefamilyfoundation.org</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer-links">
                      <h3>LEGAL</h3>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href={`${URI}/uploads/CERTIFICATE_OF_INCORPORATION_d6905084f3.pdf`}
                          >
                            Certificate of Incorporation
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="footer-links">
                      <img src="/images/123sadphene-300x190.jpg" />
                      <h3> <a href="tel:8657457237">(+91) 8657457237</a> </h3>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="newsletter">
                      <div>
                        <div className="form-group mx-sm-3 mb-2">
                          <label
                            htmlFor="inputPassword2"
                            style={{ color: "#fff" }}
                          >
                            Subscribe to our Newsletter
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="inputPassword2"
                            value={newsletter}
                            onChange={(e) => setNewsletter(e.target.value)}
                          />
                        </div>
                        {newsletterError && (
                          <p className="text-danger"> Required </p>
                        )}
                        {newsletterSuccess && (
                          <p className="text-success">
                            {" "}
                            Thank you for subscribing{" "}
                          </p>
                        )}
                        <div className="text-center">
                          <button
                            type="submit"
                            onClick={() => newsletterSubmit()}
                            className="btn btn-volunteer"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="copyright">
                    <p>
                      COPYRIGHT © 2021 ROADSIDE FAMILY FOUNDATION. ALL RIGHTS
                      RESERVED. DESIGN &amp; DEVELOPED BY{"  "}
                      <a href="https://luhaifdigitech.com/" target="_blank">
                        {"  "}
                        Luhaif Digitech{" "}
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="whatsapp-footer"
        style={{ position: "fixed", right: 20, bottom: 30 }}
      >
        <div className="whatsapp-fix">
          <a
            href="https://wa.me/918657457237?text=Hello"
            target="_blank"
            style={{ fontSize: 20 }}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/images/whatsapp.png"
                style={{ width: 40, height: 40 }}
              />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
