import api from "../utils/api";
import {
  GET_TEAMS,
  TOTAL_TEAMS,
  ADD_TEAM,
  GET_TEAM,
  RESET_TEAM,
  TEAMS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countTeam =
  (q, createdAt_lte, createdAt_gte) => async (dispatch) => {
    try {
      let query = "/teams/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_TEAMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TEAMS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getTeams =
  (limit = 10, page = 1, sort = "ASC", q, createdAt_lte, createdAt_gte) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_TEAM,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/teams?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_TEAMS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: TEAMS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
// Get posts
export const getTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_TEAM,
    });
    const res = await api.get("/teams/" + id);

    dispatch({
      type: GET_TEAM,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEAMS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addTeam = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/teams`, data);

    dispatch(setAlert("Employee Saved!", "success"));
    dispatch({
      type: ADD_TEAM,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TEAMS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editTeam = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/teams/" + id, data);

    dispatch(setAlert("Employee Updated!", "success"));
  } catch (err) {
    dispatch({
      type: TEAMS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
