import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { getTeams } from "../actions/teams";
import { URI } from "../constants/constants";
const ErrorPage = ({ getTeams, team: { loading, teams } }) => {
  return (
    <div>
      <Header />
      <BreadCrumb title="404 Page Not Found" />
      <section class="ptb-50 donate-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="donate-form">
                <h3 className="text-center"> 404 Page Not Found</h3>
                <div className="text-center">
                  <Link class="btn btn-view-details" to="/">
                    Go To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ team: state.team });

const mapDispatchToProps = { getTeams };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
