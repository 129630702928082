import React, { useState } from "react";
import { Link } from "react-router-dom";
function MobileMenu() {
  const [activeMenu, setActiveMenu] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);
  return (
    <div>
      <header>
        <div className="header">
          <div className="container">
            <div className="header-flex">
              <div className="top-social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/roadsidefamilyfoundation/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/roadsidefamilyfoundation/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/roadside_family?lang=en"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCdIaK1zy27t6NQIi1w3EoOw"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="">
                <div className="top-number-ul">
                  <ul>
                    <li>
                      <Link to="/donate-now">DONATE NOW</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end header */}
        <div className="container">
          <div className="after-header">
            <div>
              <nav>
                <div className="logo">
                  <Link to="/">
                    <img src="/images/logo.png" />
                  </Link>
                </div>
                <div className="donate-now">
                  <button
                    className="btn btn-donate-project"
                    style={{ padding: "10px" }}
                    onClick={() => setActiveMenu(!activeMenu)}
                  >
                    <i className={activeMenu ? "fa fa-close" : "fa fa-bars"} />
                  </button>
                </div>
              </nav>
              <div
                className="mobile-menu"
                style={{
                  position: "fixed",
                  display: activeMenu ? "block" : "none",
                  top: 0,
                  left: 0,
                  width: "200px",
                  zIndex: 999,
                  background: "#fff",
                  height: "100%",
                  padding: "10px 20px",
                  boxShadow: "0px 0px 25px 0px rgb(0 0 0 / 5%)",
                }}
              >
                <ul>
                  <li>
                    <Link to="/" className="active">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/projects"> Projects</Link>
                  </li>
                  <li>
                    <Link to="/donate-scrap"> Donate Scrap</Link>
                  </li>
                  <li>
                    <Link to="/gallery"> Gallery</Link>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => setSubmenuActive(!submenuActive)}
                    >
                      How Can You help?
                    </a>
                    <ul
                      style={{
                        display: submenuActive ? "block" : "none",
                        border: "1px solid #f1f1f1",
                      }}
                    >
                      <li>
                        <Link to="/donate-now">Donation</Link>
                      </li>
                      <li>
                        <Link to="/become-a-volunteer">Volunteering</Link>
                      </li>
                      <li>
                        <Link to="/become-a-member">Become A member</Link>
                      </li>
                      <li>
                        <Link to="/internship">Internship</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">CONTACT US</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default MobileMenu;
