import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { URI } from "../constants/constants";
import Spinner from "../layout/Spinner";

import Gallery from "../components/home/Gallery";
import { Helmet } from "react-helmet";
const Gallerys = ({}) => {
  return (
    <div>
      <Helmet>
        <title> Roadside Family Foundation</title>
      </Helmet>
      <Header />
      <BreadCrumb title="Our Gallery" />

      <Gallery hide />

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ gallery: state.gallery });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Gallerys);
