import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { getTeams } from "../actions/teams";
import { URI } from "../constants/constants";
import Spinner from "../layout/Spinner";
import { Helmet } from "react-helmet";
const ThankYou = () => {
  return (
    <div>
      <Helmet>
        <title>Thank You- Roadside Family Foundation</title>
      </Helmet>
      <Header />
      <BreadCrumb title="Thank You" />
      <section class="ptb-50 donate-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="donate-form text-center"
                style={{ border: "1px solid #f1f1f1", padding: "20px 0px" }}
              >
                <h2 className style={{ textAlign: "center" }}>
                  Thank you for your great generosity!
                </h2>
                <p style={{ padding: "20px 0px" }}>
                  We, at ROADSIDE FAMILY FOUNDATION, greatly appreciate your
                  donation. It is so heartening when we receive a gift from a
                  generous donor.
                </p>
                <p className="special">
                  Your support is invaluable to us, thank you again!
                </p>
                <p>Follow Us on Social Media for latest updates:</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
