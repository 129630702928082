import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Gallery from './Pages/Gallerys'
import ErrorPage from './Pages/ErrorPage'
import ThankYou from './Pages/ThankYou'
const About = lazy(() => import('./Pages/About'))
const AllBlogs = lazy(() => import('./Pages/AllBlogs'))
const DonateScrap = lazy(() => import('./Pages/DonateScrap'))
const AllCauses = lazy(() => import('./Pages/AllCauses'))
const AllProjects = lazy(() => import('./Pages/AllProjects'))
const BecomeAVolunteer = lazy(() => import('./Pages/BecomeAVolunteer'))
const ContactUs = lazy(() => import('./Pages/ContactUs'))
const DonateNow = lazy(() => import('./Pages/DonateNow'))
const FAQ = lazy(() => import('./Pages/FAQ'))
const Home = lazy(() => import('./Pages/Home'))
const SingleBlog = lazy(() => import('./Pages/SingleBlog'))
const SingleCause = lazy(() => import('./Pages/SingleCause'))
const SinglePage = lazy(() => import('./Pages/SinglePage'))
const SingleProject = lazy(() => import('./Pages/SingleProject'))
import BeforeLoginRoutes from './Routes/BeforeLoginRoutes'
import PrivateRoutes from './Routes/PrivateRoutes'
const BecomeAMember = lazy(() => import('./Pages/BecomeAMember'))
const SingleGallery = lazy(() => import('./Pages/SingleGallery'))
const BecomeAnIntern = lazy(() => import('./Pages/BecomeAnIntern'))
const renderLoader = () => (
  <img
    src="/images/spinner.gif"
    style={{ width: '200px', margin: 'auto', display: 'block' }}
    alt="Loading..."
  />
)
function Routes() {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/causes/:slug" component={SingleCause} />
        <Route exact path="/projects/:slug" component={SingleProject} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/causes" component={AllCauses} />
        <Route exact path="/projects" component={AllProjects} />
        <Route exact path="/donate-now" component={DonateNow} />
        <Route exact path="/become-a-volunteer" component={BecomeAVolunteer} />
        <Route exact path="/become-a-member" component={BecomeAMember} />
        <Route exact path="/internship" component={BecomeAnIntern} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/donate-scrap" component={DonateScrap} />
        <Route exact path="/blogs" component={AllBlogs} />
        <Route exact path="/blogs/:slug" component={SingleBlog} />
        <Route exact path="/about" component={About} />
        <Route exact path="/404" component={ErrorPage} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/gallery/:slug" component={SingleGallery} />
        <Route exact path="/:page" component={SinglePage} />
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  )
}

export default Routes
