import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getEvents } from "../../actions/events";
import { URI } from "../../constants/constants";
import Spinner from "../../layout/Spinner";
const Gallery = ({ getEvents, event: { loading, events }, hide }) => {
  useEffect(() => {
    getEvents({ limit: 10 });
  }, []);
  return (
    <section className="new-gallery">
      <div className="container">
        <div className="heading" style={{ display: hide ? "none" : "block" }}>
          <h3>Our Gallery</h3>
        </div>
        <div className="row">
          {!loading ? (
            events &&
            events.map((item, index) => {
              return (
                <div className="col-md-4">
                  <div className="gallery-image">
                    <div className="overlay">
                      <div className="event-title"> {item.name} </div>
                      <div className="event-link">
                        {" "}
                        <Link to={`/gallery/${item.slug}`}> Explore </Link>{" "}
                      </div>
                    </div>
                    <img
                      src={`${URI}${
                        item.gallery && item.gallery[0] && item.gallery[0].url
                      }`}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {" "}
              <Spinner />{" "}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({ event: state.event });

const mapDispatchToProps = { getEvents };

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
